import React from 'react';
import ReactDOM from 'react-dom';
import{BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./pages/home/home";
import Server from "./pages/server/server"
import About from "./pages/about/about"
import notfound from "./pages/404/404.js"
ReactDOM.render(
  <div>
    <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="/server" exact component={Server}/>
      <Route path="/about" exact component={About}/>
      <Route component={notfound}/>
    </Switch>
    </BrowserRouter>
    </div>,
  document.getElementById('root')
);