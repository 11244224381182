import {React, useState} from "react";
import {useHistory} from "react-router-dom"
import {isMobile} from 'react-device-detect';
import img from "../../design/creeper.jpg"
import "../../design/main.css"
function Notfound() {
  let history = useHistory();
  const [ip, setIp] = useState("");
  const getServerData = (e) => {
    history.push("./server?url="+ip)
  }
  return (
    <div className="color">
        <section id="hero" className="hero d-flex align-items-center test">
          <div className="container moveup">
          <nav class="nav centered">
            <a href="/" class="nav-link">Home</a>
            <a href="/about" class="nav-link">About</a>
          </nav>
          <br></br>
          <br></br>
          <br></br>
            <div className="row gy-4 d-flex justify-content-between">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h2 data-aos="fade-up">404 Error</h2>
                <p data-aos="fade-up" data-aos-delay={100}>We could not find this site on our server but you still can check every Status of any Minecraft Server.</p>
                <form  className="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay={200}>
                  <input type="text" className="form-control" onChange={e => setIp(e.target.value)} placeholder="Server Address" />
                  <button type="submit" onClick={getServerData}className="btn btn-primary">Check</button>
                </form>
              </div>
              {isMobile===false &&
              <div className="col-lg-5 order-1 " data-aos="zoom-out">
                <img src={img} className="img-fluid mb-3 mb-lg-0" alt=""  width="500"/>
              </div>
              }

            </div>
          </div>
        </section>
        <main id="main ">
          <section id="call-to-action" className="call-to-action test">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h3>Check Minecraft Server</h3>
                <p>Press the button to quickly test our tool</p>
                <a className="cta-btn" href="./server?url=hypixel.net">Example</a>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer" className="footer test">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <a href="index.html" className="logo d-flex align-items-center">
                  <span>MC Server Checker</span>
                </a>
                <p>Check any minecraft server of the world</p>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><a href="./">Home</a></li>
                  <li><a href="./server?url=mc.letsplayvanilla.com">Java Server Checker</a></li>
                  <li><a href="/about">About</a></li>
                  <li><a href="./server?url=bedrock.letsplayvanilla.com">Bedrock Server Checker</a></li>
                </ul>
              </div>
            </div>
          </div>   
        </footer>
    </div>
  );
}
export default Notfound;
