import {React} from "react";
function Footer(){
    return(
        <>
            <footer id="footer" className="footer test">
            <div className="container">
                <div className="row gy-4">
                <div className="col-lg-5 col-md-12 footer-info">
                    <a href="index.html" className="logo d-flex align-items-center">
                    <span>MC Server Checker</span>
                    </a>
                    <p>Check any Minecraft server status</p>
                    <p>Contact<br></br>You can reach us at minecraftserverstatus@pm.me</p>

                </div>
                <div className="col-lg-2 col-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                    <li><a href="./">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="./server?url=hypixel.net">Java Server Checker</a></li>
                    <li><a href="./server?url=bee.mc-complex.com">Bedrock Server Checker</a></li>
                    </ul>
                </div>
                </div>
            </div>   
            </footer>
        </>
    )
}
export default Footer;