import {React} from "react";
import Footer from "../../components/footerabout";
import "../../design/main.css"
function About() {
  return (
    <div className="color">    
        <link rel="canonical" href="https://minecraftserverstatus.com/about" />    
        <section id="hero" className="hero d-flex align-items-center test">
          <div className="container moveup">
          <nav class="nav centered">
            <a href="/" class="nav-link">Home</a>
            <a href="/about" class="nav-link">About</a>
          </nav>
          <br></br>
          <br></br>
          <br></br>
            <div className="row gy-4 d-flex justify-content-between">
              <div className=" order-2 order-lg-1 d-flex flex-column borderdiv">
                <h2 data-aos="fade-up" className="center">About us</h2>
                <h3 data-aos="fade-up" className="center">What does the Minecraft server status checker tool do?</h3>
                <br></br>
                <p data-aos="fade-up" data-aos-delay={100} className="center">It pings the Minecraft server address you entered and displays the following data: online status,
                signal strength, IP address, version required to play, country flag, port, players online, maximum
                players, and ping. Minecraft is a multi-platform game, and therefore servers that you play on from
                any edition of Minecraft are compatible to check with this tool. These editions include Minecraft:
                Java, Bedrock (for Windows), Pocket (for Mobile), Consoles, and other device editions.
                </p>
                <h3 data-aos="fade-up" data-aos-delay={100} className="center">How to use the Minecraft server status checker?</h3>
                <div className="divmiddle">
                  <ul>
                    <li>
                      <br></br>
                    1. Enter the Minecraft host Name or IP address in the server address box<br />
                    2. Click or tap on the “Check” button<br />
                    3. Minecraft server data will then be presented in tabulated form.<br />
                    </li>
                  </ul>
                </div>
                
                <h3 data-aos="fade-up" data-aos-delay={100} className="center">When might the tool not return Minecraft server data?</h3>
                <br></br> 
                <p data-aos="fade-up" data-aos-delay={100} className="center">If the Minecraft server is offline or you have entered an invalid server address. In this case, the Minecraft server status checker tool will display an error message for the server status.</p>
              </div>
              
            </div>
          </div>
        </section>
        
        <Footer></Footer>
    </div>
  );
}
export default About;
