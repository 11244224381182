import {React, useState} from "react";
import {useHistory} from "react-router-dom"
import {isMobile} from 'react-device-detect';
import img from "../../design/mc.png"
import Footer from "../../components/footer";
import "../../design/main.css"
import "./design.css"
function Home() {
  let history = useHistory();
  const [ip, setIp] = useState("");
  const getServerData = (e) => {
    history.push("./server?url="+ip)
  }
  return (
    <div className="color">
        <link rel="canonical" href="https://minecraftserverstatus.com/" />    
        <section id="hero" className="hero d-flex align-items-center test">
          <div className="container moveup">
          <nav class="nav centered">
            <a href="/" class="nav-link">Home</a>
            <a href="/about" class="nav-link">About</a>
          </nav>
          <br></br>
          <br></br>
          <br></br>
            <div className="row gy-4 d-flex justify-content-between">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h2 data-aos="fade-up">Your Minecraft Network Status Checker</h2>
                <p data-aos="fade-up" data-aos-delay={100}>Check the status of any Minecraft server for all versions of Minecraft. You can check the
                server status for Minecraft: Java, Bedrock, and Pocket Editions without your Minecraft
                launcher from the convenience of your browser</p>
                <form  className="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay={200}>
                  <input type="text" className="form-control" onChange={e => setIp(e.target.value)} placeholder="Server Address" />
                  <button type="submit" onClick={getServerData}className="btn btn-primary">Check</button>
                </form>
              </div>
              {isMobile===false &&
              <div className="col-lg-5 order-1 " data-aos="zoom-out">
                <img src={img} className="img-fluid mb-3 mb-lg-0" alt=""  width="500"/>
              </div>
              }
            </div>
          </div>
        </section>
        <main id="main ">
          <section id="call-to-action" className="call-to-action test">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h3>Check Minecraft Server</h3>
                <p>Press the button to quickly test our tool</p>
                <a className="cta-btn" href="./server?url=hypixel.net">Example</a>
              </div>
            </div>
          </section>
        </main>
        <Footer></Footer>
    </div>
  );
}
export default Home;
