
import {React, useState,useEffect} from "react";
import CountUp from 'react-countup';
import Axios from "axios"
import { useLocation } from 'react-router-dom'

import {useHistory} from "react-router-dom"
import 'react-bootstrap-icons';
import "./table.css"
import Footer from "../../components/footerserversite";
function Server() {
  let history = useHistory();
  const [newIp, setNewIp] = useState("");
  const [signal, setSignal] = useState("");
  const[server, setServer] = useState({})
  const[bedrock, setBedrock] = useState({})
  const[error, setError] = useState(false)
  const [currentip, setCurrentIp] = useState("");

  const [javaselected, setJavaSelected] = useState(true)
  const[ping, setPing]= useState(0)
  const location = useLocation();

  const getServerData = (e) => {
    e.preventDefault();
    if(newIp!==""){
      setServer({})
      history.push("./server?url="+newIp)
      window.location.reload(true);
    }
  }
  const changeVersion = (e) => {
    setJavaSelected(!javaselected)
  }

  function redirect(split){
    if(split.length!==2){
      history.push("/")
    }
    if(split[1]===""){
      history.push("/")
    }
  }
  useEffect(()=>{
    window.scrollTo(0, 0)

    var query = location.search;
    var split= query.split("=")
    setCurrentIp(split[1])
    redirect(split)
    Axios.post("https://minecraftserverstatus.com:3000/getData", {
        ip_address: split[1]
    }).then((response)  => {
      if(response.data.error!==undefined){
        setError(true)
      }else{
        setServer(response.data.java)

        if(response.data.java.data.online===false){
          setJavaSelected(false)
        }
        if(response.data.bedrock.online!==false){
          setBedrock(response.data.bedrock)
        }
      }
      console.log(response)
    });
    Axios.post("https://minecraftserverstatus.com:3000/getPing", {
        ip_address: split[1]
    }).then((response)  => {
      if(response.data!==undefined){
        setPing(response.data.ping)
        setSignal(response.data.signal)
      }
    });
    // eslint-disable-next-line
 },[]);
  
  return (
    <div>
      {(() => {
        if (server!==undefined && server.data!==undefined && javaselected===true) {
          return (
            <div>
              <link rel="canonical" href="https://minecraftserverstatus.com/server" />    
              <section id="hero" className="hero d-flex align-items-center">
              <div className="container moveupv2">
              <nav class="nav centered">
                <a href="/" class="nav-link">Home</a>
                <a href="/" class="nav-link">Back</a>
                <a href="/about" class="nav-link">About</a>
              </nav>
              <br></br>
              <br></br>
                <div className="row gy-4 d-flex justify-content-between">
                  <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h2 data-aos="fade-up">{server.data.hostname}</h2>
                    <p data-aos="fade-up" data-aos-delay={100}>Check every status of any Minecraft Server. It does not matter if you want to check the status of a big java minecraft server or a small bedrock server.</p>
                    <form  className="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay={200}>
                      <input type="text" className="form-control" onChange={e => setNewIp(e.target.value)} placeholder="Server Address" />
                      <button type="submit" onClick={getServerData}className="btn btn-primary">Check</button>
                    </form>
                    {server.data.online.toString() === "true" &&
                    <div className="row gy-4" data-aos="fade-up" data-aos-delay={400}>
                    <div className="col-lg-3 col-6">
                        <div className="stats-item text-center w-100 h-100">
                        <CountUp end={server.data.port} className="purecounter" />
                          <p>Port:</p>
                        </div>
                      </div>{/* End Stats Item */}
                      <div className="col-lg-3 col-6">
                        <div className="stats-item text-center w-100 h-100">
                          <CountUp end={server.data.players.online} className="purecounter" />
                          <p>Online</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-6">
                        <div className="stats-item text-center w-100 h-100">
                        <CountUp end={server.data.players.max} className="purecounter" />
                          <p>Max Players</p>
                        </div>
                      </div>{/* End Stats Item */}
                      <div className="col-lg-3 col-6">
                          <div className="stats-item text-center w-100 h-100">
                          <CountUp end={ping} className="purecounter" />
                            <p>Ping</p>
                          </div>
                        </div>{/* End Stats Item */}    
                    </div>
                  }
                  </div>
                  <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                  {server.data.online.toString() === "true" &&
                    <div>
                      <br></br><br></br><br></br>
                      <table>
                      <thead>
                        <tr>
                          <th>
                            Service
                          </th>
                          <th>
                            Data
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title='Provider Name'>
                            Online Status:
                          </td>
                          <td data-title='data'>
                            <span style={{ color: "#55FF55" }}>{server.data.online.toString()}</span>
                          </td>
                        </tr>
                        <tr>
                          <td data-title='Provider Name'>
                            Signal:
                          </td>
                          <td data-title='data'>
                            {signal=== "strong" &&
                              <span style={{ color: "#55FF55" }}>Strong</span>
                            }
                            {signal=== "average" &&
                              <span style={{ color: "#ff8800" }}>Average</span>
                            }
                            {signal=== "low" &&
                              <span style={{ color: "#FF5555" }}>Low</span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <td data-title='Provider Name'>
                            Address:
                          </td>
                          <td data-title='data'>
                            {server.data.ip}
                          </td>
                        </tr>
                        <tr>
                          <td data-title='Provider Name'>
                            Version:
                          </td>
                          <td data-title='data'>
                            {server.data.version}
                          </td>
                        </tr>
                        <tr>
                          <td data-title='Provider Name'>
                            Country:
                          </td>
                          <td data-title='data'>
                            <img
                            alt="United States"
                            src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/"+server.data.country+".svg"} height={20}/>
                          </td>
                        </tr>
                      </tbody>
                      <br></br>
                    </table>
                    {bedrock.hostname !== undefined && server.data.port!==19132 &&
                      <div>
                        <button type="submit"onClick={changeVersion}className="btn btn-primary buttonsize">Get Data of Bedrock Version of this Server</button>
                      </div>}                    <br></br>
                    <br></br>
                    <div className="content" dangerouslySetInnerHTML={{__html: server.data.motd.html}} ></div>
                    <br></br>
                    <img src={server.data.icon}className="img-fluid mb-3 mb-lg-0" alt="" />
                  </div>}
                  {server.data.online.toString() !== "true" &&
                    <div>
                    <p data-aos="fade-up" data-aos-delay={100}>Online-Status: <span style={{ color: "#FF5555" }}>{server.data.online.toString()}</span><br></br></p>
                    <p data-aos="fade-up" data-aos-delay={100}>Error: <span style={{ color: "#FF5555" }}>{server.data.debug.error.ping}</span><br></br></p>
                    <br></br>
                  </div>}
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="call-to-action" className="call-to-action">
                <div className="row justify-content-center">
                  <div className="col-lg-8 text-center">
                    <h3>Check more Minecraft Server?</h3>
                    <p>If you want to check more Minecraft Server just go back here.</p>
                    <a className="cta-btn" href="./">Go back</a>
                  </div>
                </div>
              </section>
            </main>
            <Footer></Footer>
            
            </div>
          )
         }else if (bedrock.online!==undefined && javaselected===false) {
            return (
              <div>
				<nav class="nav centered">
					<a href="/" class="nav-link">Home</a>
					<a href="/" class="nav-link">Back</a>
					<a href="/about" class="nav-link">About</a>
				  </nav>
                <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                  <div className="row gy-4 d-flex justify-content-between">
                    <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                      <h2 data-aos="fade-up">{bedrock.hostname}</h2>
                      <p data-aos="fade-up" data-aos-delay={100}>Check the status of any Minecraft Server. It does not matter if you want to check the status of a big java minecraft server or a small bedrock server.</p>
                      <form  className="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay={200}>
                        <input type="text" className="form-control" onChange={e => setNewIp(e.target.value)} placeholder="Server Address" />
                        <button type="submit" onClick={getServerData}className="btn btn-primary">Check</button>
                      </form>
                      {bedrock.online.toString() === "true" &&
                      <div className="row gy-4" data-aos="fade-up" data-aos-delay={400}>
                      <div className="col-lg-3 col-6">
                          <div className="stats-item text-center w-100 h-100">
                          <CountUp end={bedrock.port} className="purecounter" />
                            <p>Port:</p>
                          </div>
                        </div>{/* End Stats Item */}
                        <div className="col-lg-3 col-6">
                          <div className="stats-item text-center w-100 h-100">
                            <CountUp end={bedrock.players.online} className="purecounter" />
                            <p>Online</p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-6">
                          <div className="stats-item text-center w-100 h-100">
                          <CountUp end={bedrock.players.max} className="purecounter" />
                            <p>Max Players</p>
                          </div>
                        </div>{/* End Stats Item */}
                        <div className="col-lg-3 col-6">
                            <div className="stats-item text-center w-100 h-100">
                            <CountUp end={ping} className="purecounter" />
                              <p>Ping</p>
                            </div>
                          </div>{/* End Stats Item */}    
                      </div>
                    }
                    </div>
                    <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                    {bedrock.online.toString() === "true" &&
                      <div>
                        <br></br><br></br><br></br>
                        <table>
                        <thead>
                          <tr>
                            <th>
                              Service
                            </th>
                            <th>
                              Data
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-title='Provider Name'>
                              Online Status:
                            </td>
                            <td data-title='data'>
                              <span style={{ color: "#55FF55" }}>{bedrock.online.toString()}</span>
                            </td>
                          </tr>
                          <tr>
                            <td data-title='Provider Name'>
                              Signal:
                            </td>
                            <td data-title='data'>
                              {signal=== "strong" &&
                                <span style={{ color: "#55FF55" }}>Strong</span>
                              }
                              {signal=== "average" &&
                                <span style={{ color: "#ff8800" }}>Average</span>
                              }
                              {signal=== "low" &&
                                <span style={{ color: "#FF5555" }}>Low</span>
                              }
                            </td>
                          </tr>
                          <tr>
                            <td data-title='Provider Name'>
                              Address:
                            </td>
                            <td data-title='data'>
                              {bedrock.ip}
                            </td>
                          </tr>
                          <tr>
                            <td data-title='Provider Name'>
                              Version:
                            </td>
                            <td data-title='data'>
                              {bedrock.version}
                            </td>
                          </tr>
                          <tr>
                            <td data-title='Provider Name'>
                              Country:
                            </td>
                            <td data-title='data'>
                              <img
                              alt="United States"
                              src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/"+bedrock.country+".svg"} height={20}/>
                            </td>
                          </tr>
                        </tbody>
                        <br></br>
                      </table>
                      {server.data !== undefined && server.data.online===true&&
                      <div>
                        <button type="submit"onClick={changeVersion}className="btn btn-primary buttonsize">Get Data of Java Server</button>
                      </div>}
                      <br></br>
                      <br></br>
                      <div className="content" dangerouslySetInnerHTML={{__html: bedrock.motd.html}} ></div>
                      <br></br>
                      <img src={bedrock.icon}className="img-fluid mb-3 mb-lg-0" alt="" />
                    </div>}
                    </div>
                  </div>
                </div>
              </section>

              <main id="main">
            <section id="call-to-action" className="call-to-action">
              <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                  <h3>Check more Minecraft Server?</h3>
                  <p>If you want to check more Minecraft Server just go back here.</p>
                  <a className="cta-btn" href="./">Go back</a>
                </div>
              </div>
            </section>
          </main>
              <Footer></Footer>
              </div>
            )
            // eslint-disable-next-line
        }else if(error===true|| (server.data!==undefined && server.data.online!==true) && (bedrock!==undefined && bedrock.online!==true)){
          return (
            <div>
              <section id="hero" className="hero d-flex align-items-center">
              <div className="container">
                <div className="row gy-4 d-flex justify-content-between">
                  <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h2 data-aos="fade-up">{currentip}</h2>
                    <p data-aos="fade-up" data-aos-delay={100}>Check the Status of any Minecraft Server. It does not matter if you want to check the status of a big java minecraft server or a small bedrock server.</p>
                    <form  className="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay={200}>
                      <input type="text" className="form-control" onChange={e => setNewIp(e.target.value)} placeholder="Server Address" />
                      <button type="submit" onClick={getServerData}className="btn btn-primary">Check</button>
                    </form>
                  </div>
                  <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                     <p data-aos="fade-up" data-aos-delay={100}>Online-Status: <span style={{ color: "#FF5555" }}>Offline</span><br></br></p>
                    <p data-aos="fade-up" data-aos-delay={100}>Error: <span style={{ color: "#FF5555" }}>Could not connect to the address</span><br></br></p>
                    <br></br>
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="call-to-action" className="call-to-action">
                <div className="row justify-content-center">
                  <div className="col-lg-8 text-center">
                    <h3>Check more Minecraft Servers?</h3>
                    <p>If you want to check another Minecraft server, just press the button.</p>
                    <a className="cta-btn" href="./">Go back</a>
                  </div>
                </div>
              </section>
            </main>
            <footer id="footer" className="footer test">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-5 col-md-12 footer-info">
                    <a href="index.html" className="logo d-flex align-items-center">
                      <span>MC Server Checker</span>
                    </a>
                    <p>Check any minecraft server of the world</p>
                    <p>Contact <br></br>You can reach us at minecraftserverstatus@pm.me</p>
                  </div>
                  <div className="col-lg-2 col-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                      <li><a href="./">Home</a></li>
                      <li><a href="/about">About</a></li>
                      <li><a href="/">Go back</a></li>
                    </ul>
                  </div>
                </div>
              </div>   
            </footer>
           </div>
         )}else {
           return (
            <div>
              <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                  <div className="row gy-4 d-flex justify-content-between">
                    <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                      <h2 data-aos="fade-up">Loading...</h2>
                      <p data-aos="fade-up" data-aos-delay={100}>Check the status of any Minecraft server for all versions of Minecraft. You can check the server status for Minecraft: Java, Bedrock, and Pocket Editions without your Minecraft launcher from the convenience of your browser</p>
                      <p data-aos="fade-up" data-aos-delay={100}>If this page does not load, please refresh the website or check your server address.</p>
                    </div>
                  </div>
                </div>
              </section>
              <main id="main">
                <section id="call-to-action" className="call-to-action">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                      <h3>Check more Minecraft Servers?</h3>
                      <p>If you want to check another Minecraft server, just press the button.</p>
                      <a className="cta-btn" href="./">Go back</a>
                    </div>
                  </div>
                </section>
              </main>
             <Footer></Footer>
          </div>
          )
        }
      })()}
    </div>
  );
}
export default Server;
